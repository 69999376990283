<template>
  <div>
    <Menu />
    <div id="aboutus">
      <!--  -->
      <div class="AboutUs">
        <img src="../assets/images/aboutUs_banner.png" class="banner w100" />
        <div class="content bg_fff nContent">
          <!-- crumbs -->
          <div class="crumbs">
            <div class="content d-flex d-flex-middle">
              <p class="color9 fontSize12">当前所在位置：</p>
              <router-link to="/" class="color9 fontSize12">首页</router-link>
              <p class="color9 fontSize12 and">></p>
              <p class="color fontSize12">关于我们</p>
            </div>
          </div>
          <!-- tabs -->
          <div class="tabs d-flex d-flex-middle d-flex-center">
            <p :class="{active:aboutType=='公司简介'}" @click="changeAboutType('公司简介')">公司简介</p>
            <p :class="{active:aboutType=='企业资质'}" @click="changeAboutType('企业资质')">企业资质</p>
            <p :class="{active:aboutType=='企业文化'}" @click="changeAboutType('企业文化')">企业文化</p>
          </div>
          <!--  -->
          <!-- 公司简介 -->
          <div class="tabContent abContent" v-if="aboutType=='公司简介'">
            <div v-html="aboutData"></div>
          </div>
          <!-- 企业资质 -->
          <div class="tabContent imgs" v-if="aboutType=='企业资质'">
            <div v-html="aboutData"></div>
            <!-- <div class="d-flex d-flex-middle d-flex-wrap">
						<div class="tabContent_li">
							<div class="diyImg" style="background-image: url(../assets/images/bottomImg.png);"></div>
							<p class="color3 fontSize16 txtOver">2018年度影响力在线教育品牌</p>
						</div>
						<div class="tabContent_li">
							<div class="diyImg" style="background-image: url(../assets/images/bottomImg.png);"></div>
							<p class="color3 fontSize16 txtOver">2018年度影响力在线教育品牌</p>
						</div>
						<div class="tabContent_li">
							<div class="diyImg" style="background-image: url(../assets/images/bottomImg.png);"></div>
							<p class="color3 fontSize16 txtOver">2018年度影响力在线教育品牌</p>
						</div>
						<div class="tabContent_li">
							<div class="diyImg" style="background-image: url(../assets/images/bottomImg.png);"></div>
							<p class="color3 fontSize16 txtOver">2018年度影响力在线教育品牌</p>
						</div>
						<div class="tabContent_li">
							<div class="diyImg" style="background-image: url(../assets/images/bottomImg.png);"></div>
							<p class="color3 fontSize16 txtOver">2018年度影响力在线教育品牌</p>
						</div>
					</div> -->
          </div>
          <!-- 企业文化 -->
          <div class="tabContent abContent" v-if="aboutType=='企业文化'">
            <div v-html="aboutData"></div>
          </div>
        </div>
      </div>
    </div>
    <Foot />
    <RightGuide />

  </div>
</template>

<script>
import Menu from "@/components/Menu";
import Foot from "@/components/Foot";
import RightGuide from "@/components/RightGuide";
import { agreement } from "@/api/app"
export default {
  components: {
    Menu, Foot, RightGuide
  },
  data() {
    return {
      aboutType: '公司简介',
      aboutData: {}
    };
  },
  mounted() {
    this.getAgreement()
  },

  methods: {
    changeAboutType(v) {
      this.aboutType = v
	  this.getAgreement()
    },


    //联系我们信息
    getAgreement() {
      const params = {
        key: this.aboutType
      }
      agreement(params).then((res) => {
        if (res.status) {
          this.aboutData = res.data
          console.log(this.aboutData)
        }
      })
    },

  },
};
</script>

<style lang="scss">
#aboutus {
  /*  */
  .AboutUs {
    position: relative;
    box-sizing: border-box;
    padding-top: 270px;
    min-height: 800px;
    padding-bottom: 100px;
  }
  .banner {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .nContent {
    position: relative;
    z-index: 22;
    background-color: #fff;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 0 40px;
    min-height: 800px;
  }
  .tabs {
    margin: 20px 0;
  }
  .tabs p {
    width: 130px;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    text-align: center;
    background: #f7f7f8;
    margin: 0 5px;
    font-size: 16px;
  }
  .tabs .active {
    background-color: #0087ed;
    color: #fff;
  }
  /*  */
  .tabContent {
    /* display: none; */
  }
  .tabContent.show {
    display: block;
  }
  /*  */
  .abContent {
    margin-top: 70px;
    padding-bottom: 50px;
  }
  .abContent img {
    display: block;
    width: 755px;
    margin: 0 auto 50px auto;
  }
  .abContent p {
    line-height: 24px;
    text-indent: 2em;
    margin-bottom: 10px;
    font-size: 16px;
  }
  /*  */
  .imgs {
    margin-top: 70px;
  }
  .tabContent_li {
    text-align: center;
    width: calc(100% / 3);
    margin-bottom: 20px;
  }
  .tabContent_li div {
    width: 306px;
    height: 254px;
    margin-bottom: 10px;
    margin: 0 auto 10px auto;
  }
  .tabContent_li p {
    height: 30px;
    line-height: 30px;
  }
}

/*  */
body {
  background-color: #fafafa;
}
</style>